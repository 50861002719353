var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrap"},[_c('transition',{attrs:{"name":"toggle-parameter","mode":"out-in"}},[(_vm.loadingCustomblockDetail)?_c('div',{key:"loading",staticClass:"loading"},[_c('loading-icon')],1):(Object.keys(_vm.parameters).length)?_c('div',{key:"contents",staticClass:"body"},[_c('div',{staticClass:"main"},[_c('div',{staticClass:"title"},[_c('texts',{attrs:{"text":_vm.$t('recipe.sidebar.parameter.title'),"size":"large"}})],1),_c('text-with-title',{staticClass:"name",attrs:{"title":_vm.$t('recipe.sidebar.parameter.name'),"text":_vm.getLayerName(_vm.parameters.node)}})],1),(_vm.parameters.node.name === 'customblock')?_c('div',{staticClass:"main"},[(_vm.edit)?[_c('texts',{staticClass:"c-textblock-title",attrs:{"size":"small","color":"gray","text":_vm.$t('recipe.sidebar.parameter.customblockVersion')}}),_c('div',{staticClass:"c-textblock-wrap"},[_c('select-box',{attrs:{"firstSelectItem":{
                name: _vm.parameters.node.customblockVersion,
                value: _vm.parameters.node.customblockVersion
              },"items":_vm.customblockVersions,"isGray":"","showInfo":""},on:{"input":_vm.versionSelectInput,"select-box-open":_vm.versionSelectOpen}})],1)]:_c('text-with-title',{attrs:{"title":_vm.$t('recipe.sidebar.parameter.customblockVersion'),"text":_vm.parameters.node.customblockVersion}})],2):_vm._e(),_c('transition',{attrs:{"name":"toggle-parameter","mode":"out-in"}},[(_vm.parameters.node.name === 'inputData')?_c('div',{key:"input",staticClass:"input"},_vm._l((_vm.parameters.node.params),function(param,key){return _c('div',{key:key,staticClass:"item"},[_c('transition',{attrs:{"name":"toggle-parameter","mode":"out-in"}},[(key === 'dataType')?_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":_vm.setOptions(_vm.parameters.node.name, param.options),"selectChange":_vm.changeInputDataType,"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}}):(
                  _vm.parameters.node.params.dataType.value === 'figures' &&
                    (key === 'dataShuffle' || key === 'fixSplit')
                )?_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":param.options,"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}}):(
                  _vm.parameters.node.params.dataType.value === 'images' &&
                    key !== 'dataShuffle' &&
                    key !== 'fixSplit'
                )?_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":param.options,"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}}):_vm._e()],1)],1)}),0):(_vm.parameters.node.name === 'AutoFlow')?_c('div',{key:"algo",staticClass:"algo-wrap"},[_vm._l((_vm.parameters.node.params),function(param,key,index){return [(key === 'type')?_c('div',{key:key + index,staticClass:"item"},[_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":_vm.setOptions(_vm.parameters.node.name, param.options),"selectChange":_vm.changeInputDataType,"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}})],1):(
                key === 'classMetrics' &&
                  _vm.parameters.node.params.type.value === 'classification'
              )?_c('div',{key:key + index,staticClass:"item"},[_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":_vm.setOptions(_vm.parameters.node.name, param.options),"selectChange":_vm.changeInputDataType,"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue,"scrollBar":"","scrollSize":150},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}})],1):(key === 'nTrials')?_c('div',{key:key + index,staticClass:"item"},[_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":param.options,"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}})],1):(
                key === 'classSplitter' &&
                  _vm.parameters.node.params.type.value === 'classification'
              )?_c('div',{key:key + index,staticClass:"item"},[_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":_vm.setOptions(_vm.parameters.node.name, param.options),"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue,"scrollBar":"","scrollSize":150},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}})],1):(
                key === 'regSplitter' &&
                  _vm.parameters.node.params.type.value === 'regression'
              )?_c('div',{key:key + index,staticClass:"item"},[_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":_vm.setOptions(_vm.parameters.node.name, param.options),"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue,"scrollBar":"","scrollSize":150},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}})],1):(key === 'nCvSplits')?_c('div',{key:key + index,staticClass:"item"},[_c('parameter',{attrs:{"layerName":_vm.parameters.node.name,"name":key,"edit":_vm.edit,"type":param.type,"value":param.value,"extra_selections":param.extra_selections,"options":_vm.setOptions(_vm.parameters.node.name, param.options),"step":param.step,"force-step":param.forceStep,"min":param.min,"max":param.max,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue},on:{"input":function($event){return _vm.$emit('input', {
                    param: $event,
                    paramName: key,
                    blockId: _vm.parameters.node.id
                  })}}})],1):(key === 'algorithm')?_c('div',{key:key + index,staticClass:"algo-block"},[_c('button',{staticClass:"algo-button",class:{ 'algo-button-toggle': _vm.showAutoFlowAlgorithm },on:{"click":function($event){_vm.showAutoFlowAlgorithm = !_vm.showAutoFlowAlgorithm}}},[_c('icons',{staticClass:"algo-button-icon",attrs:{"iconName":"toggle"}}),_c('texts',{attrs:{"text":_vm.$t('recipe.autoflow.' + key)}})],1),_c('transition',{attrs:{"name":"toggle-algo","mode":"out-in"}},[(_vm.showAutoFlowAlgorithm)?_c('div',{staticClass:"algo-inner"},_vm._l((_vm.autoFlowAlgorithmList),function(algo,index){return _c('div',{key:index,staticClass:"algo-item"},[_c('button',{staticClass:"algo-item-button",on:{"click":function($event){return _vm.showArgoDetail(index)}}},[_c('div',{staticClass:"algo-item-checkbox"},[_c('checkbox-base',{attrs:{"checked":algo.active,"isDisabled":!_vm.edit},model:{value:(algo.active),callback:function ($$v) {_vm.$set(algo, "active", $$v)},expression:"algo.active"}}),_c('texts',{staticClass:"algo-item-checkbox-text",attrs:{"text":_vm.$t('recipe.layerNames.' + algo.name),"size":"small"}})],1),_c('div',{staticClass:"algo-item-icon",class:{
                          'algo-item-icon-toggle':
                            _vm.showParamFlagList.indexOf(index) > -1
                        }},[_c('icons',{attrs:{"iconName":"toggle"}})],1)]),_c('transition',{attrs:{"name":"toggle-algo","mode":"out-in"},on:{"enter":function($event){return _vm.enter(_vm.$refs.target[index])},"leave":function($event){return _vm.leave(_vm.$refs.target[index])}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showParamFlagList.indexOf(index) > -1),expression:"showParamFlagList.indexOf(index) > -1"}],ref:"target",refInFor:true,staticClass:"algo-detail"},_vm._l((algo.params),function(pp,pk){return _c('div',{key:pk,staticClass:"algo-detail-list"},[(pp.type === 'select')?_c('div',{staticClass:"algo-detail-item"},[_c('div',{staticClass:"algo-detail-title"},[_c('texts',{attrs:{"text":_vm.$t('recipe.param.' + algo.name + '.' + pk),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"algo-detail-value"},[_vm._l((pp.options),function(option,index){return [_c('texts',{key:index,staticClass:"algo-detail-value-text",attrs:{"text":_vm.$t(
                                      'recipe.param.' +
                                        algo.name +
                                        '.' +
                                        _vm.checkValueEscape(option.text)
                                    )}})]})],2)]):_c('div',{staticClass:"algo-detail-item"},[_c('div',{staticClass:"algo-detail-title"},[_c('texts',{attrs:{"text":_vm.$t('recipe.param.' + algo.name + '.' + pk),"size":"small","color":"gray"}})],1),_c('div',{staticClass:"algo-detail-value"},[_c('texts',{attrs:{"text":_vm.$t('recipe.autoflow.range')}})],1),_c('div',{staticClass:"algo-detail-range"},[_c('div',{staticClass:"algo-detail-range-item"},[_c('texts',{attrs:{"text":_vm.$t('recipe.autoflow.low'),"size":"small","color":"gray"}}),_c('texts',{attrs:{"text":pp.low}})],1),_c('div',{staticClass:"algo-detail-range-item"},[_c('texts',{attrs:{"text":_vm.$t('recipe.autoflow.high'),"size":"small","color":"gray"}}),_c('texts',{attrs:{"text":pp.high}})],1)])])])}),0)])],1)}),0):_vm._e()])],1):_vm._e()]})],2):(Object.keys(_vm.parameters.node.params).length > 0)?_c('div',{key:"list",staticClass:"list"},[_c('transition-group',{attrs:{"name":"toggle-parameter","mode":"out-in"}},[_vm._l((_vm.parameters.node.params),function(param,name,index){return [(
                  _vm.showParams(param, name, _vm.parameters.node.params) &&
                    _vm.displayParams(param)
                )?_c('div',{key:param + index + name + _vm.parameters.node.id,staticClass:"item"},[(name === 'act')?_c('div',{staticClass:"param"},[_c('div',{staticClass:"param-name"},[_c('texts',{staticClass:"param-name-text",attrs:{"text":_vm.$t('recipe.param.activation'),"size":"small","color":"gray","isShowAll":""}})],1),_c('div',{staticClass:"param-body"},[(_vm.edit)?_c('div',{staticClass:"param-selectbox"},[_c('select-box',{attrs:{"firstSelectItem":{
                          name: _vm.activationText(param.value),
                          value: param.value
                        },"items":_vm.activationOptions,"isGray":"","min":""},on:{"input":function($event){return _vm.$emit('input', {
                            param: $event,
                            paramName: name,
                            blockId: _vm.parameters.node.id
                          })}}})],1):_c('div',[_c('texts',{attrs:{"text":_vm.activationText(param.value)}})],1)])]):_c('parameter',{attrs:{"param":param,"name":name,"translatedName":_vm.getParamName(_vm.parameters.node, name),"type":param.type,"value":param.value,"layerName":_vm.parameters.node.name,"edit":_vm.edit,"extra_selections":param.extra_selections,"options":_vm.setOptions(_vm.parameters.node.name, param.options),"step":param.step,"force-step":param.forceStep,"min":_vm.paramMin(param),"max":_vm.paramMax(param),"list_body":param.list_body,"defaultValue":param.defaultValue,"defaultNumValue":param.defaultNumValue,"disabled":_vm.checkDisabled(param, name)},on:{"input":function($event){return _vm.$emit('input', {
                      param: $event,
                      paramName: name,
                      blockId: _vm.parameters.node.id
                    })}}})],1):_vm._e()]})],2)],1):_vm._e()]),_c('transition',{attrs:{"name":"toggle-parameter","mode":"out-in"}},[(_vm.parameters.incomeEdges.length || _vm.parameters.outgoEdges.length)?_c('div',{staticClass:"edge-wrap"},[_c('texts',{staticClass:"edge-title",attrs:{"text":_vm.$t('recipe.sidebar.ConnectedLayer'),"color":"gray","size":"small"}}),_c('div',{staticClass:"edge-list"},[_c('transition-group',{staticClass:"edge-list-inner",attrs:{"name":"toggle-parameter","mode":"out-in"}},_vm._l((_vm.connectionEdges),function(edge,index){return _c('div',{key:index + '_edge',staticClass:"edge-item"},[_c('text-with-icon',{staticClass:"edge-item-inner",attrs:{"text":_vm.convertCustomblockName(edge.customblockId, edge.name),"iconName":edge.iconName}}),(_vm.edit)?_c('icons',{staticClass:"edge-item-button",attrs:{"iconName":"close","isButton":"","size":"small"},on:{"icon-click":function($event){return _vm.$emit('remove-edge', edge.id)}}}):_vm._e()],1)}),0)],1)],1):_vm._e()])],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }